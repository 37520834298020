.firstSectionWrapper {
  text-align: center;
  color: #1f3a45;
  position: relative;
  padding-top: 44px;
  margin-right: 12px;
  margin-left: 12px;
}

.main {
  padding-bottom: 144px;
}

.Main_title_wrapper {
  position: relative;
  z-index: 2;
  width: 930px;
  max-width: 100%;
  margin: auto;
}

#pricing {
  margin-bottom: 44px;
  padding-top: 80px;
  position: relative;
  z-index: 2;
}

.Deco {
  position: absolute;
  z-index: 1;
}

.Deco img {
  opacity: 0.15;
  user-select: none;
}

.Main_title_decoration1 {
  top: 20px;
  left: 5%;
}

.Main_title_decoration2 img {
  width: 100px;
}

.Main_title_decoration2 {
  top: 165px;
  right: 12%;
}

.Main_title_decoration1 img {
  width: 140px;
}

.Main_title_decoration3 {
  top: 250px;
  left: 4%;
  transform: rotate(-45deg);
}

.Main_title_decoration3 img {
  width: 200px;
}

.Main_title_decoration4 {
  top: 450px;
  right: 16%;
  transform: rotate(45deg);
}

.Main_title_decoration4 img {
  width: 250px;
}

.Main_title {
  text-align: center;
  max-width: calc(100% - 8px);
  margin: auto;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 56px;
  line-height: 66px;
  width: 620px;
}

.Main_subtitle {
  color: #4b4b4b;
  font-size: 22px;
  text-align: center;
  font-weight: 300;
  width: 570px;
  max-width: calc(100% - 8px);
  margin: auto;
  margin-top: 28px;
  padding-left: 4px;
  padding-right: 4px;
}

.subtitle {
  color: #4b4b4b;
  font-size: 20px;
  text-align: center;
  font-weight: 300;
  width: 560px;
  max-width: calc(100% - 12px);
  margin: auto;
  margin-top: 20px;
  margin-bottom: 26px;
  padding-left: 6px;
  padding-right: 6px;
}

.topQuoteWrapper {
  width: 555px;
  margin: auto;
  max-width: 100%;
  margin-top: 24px;
}

.topQuoteContent {
  background-color: #ffffff73;
  padding: 16px 28px;
  border-radius: 50px;
  font-style: italic;
  box-shadow: 0px 0px 12px rgb(0 0 0 / 6%);
}

.topQuoteAvatar {
  background-color: #f0f1fc;
  border-radius: 46px;
  box-shadow: 0px 0px 12px rgb(0 0 0 / 6%);
  margin: auto;
  margin-top: -10px;
  width: fit-content;
  font-size: 15px;
  padding: 0;
  padding-right: 13px;
  position: relative;
  z-index: 1;
}

.topQuoteG2 {
  background-color: #f0f1fc;
  border-radius: 46px;
  box-shadow: 0px 0px 12px rgb(0 0 0 / 6%);
  margin: auto;
  margin-bottom: -10px;
  width: fit-content;
  padding: 8px 18px;
  padding-left: 12px;
  position: relative;
  z-index: 1;
}

.Main_CTA_wrapper {
  margin-top: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Main_CTA_wrapper button {
  display: flex;
  align-items: center;
  margin: auto;
}

.button img {
  width: 24px;
  height: 24px;
  margin-left: 12px;
}


.center {
  text-align: center;
}


.Success_wrapper {}

.Success_inner {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Pricing_wrapper {}

.Success_icon img {
  padding: 12px;
  padding-bottom: 0px;
}

.titleSuccessWrapper {
  display: flex;
  align-items: center;
}

.titleSuccess {
  margin-left: 20px;
}

.privacy_wrapper {
  width: 800px;
  max-width: 100%;
  margin: auto;
}

.Contact_card_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 180px;
}

.Contact_card_inner {
  width: 420px;
  max-width: 100%;
  margin: 12px;
  background-color: white;
  padding: 40px;
  border-radius: 12px;
  text-align: center;
}

.Contact_card_title {
  font-size: 24px;
  margin-bottom: 12px;
  color: #2f2f2f;
}

.Contact_card_hint {
  margin-top: 12px;
  font-size: 16px;
}

.Screens_wrapper {
  position: relative;
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  max-width: 100%;
}

.Screen_capture_1 {
  background-color: white;
  position: relative;
  border-radius: 22px;
  padding: 28px;
  margin: 14px;
  display: flex;
  margin-right: -44px;
  margin-left: 44px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.Screen_capture_2 {
  display: flex;
  background-color: white;
  position: relative;
  border-radius: 22px;
  padding: 28px;
  margin: 14px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.Screens_icon {
  position: absolute;
  top: -116px;
  left: -24px;
  font-size: 95px;
  z-index: 1;
}

.Screens_icon_1 {
  position: absolute;
  top: -88px;
  left: 324px;
  font-size: 95px;
  z-index: 1;
}

.Screens_icon_2 {
  position: absolute;
  bottom: -42px;
  right: -40px;
  font-size: 100px;
  z-index: 1;
}

.Screens_vertical_separation {
  height: 100%;
  min-height: 330px;
}

.Header_marketing_margin {
  margin-top: 44px;
}

.Teams_logo {
  width: 30px;
  height: 30px;
}

.Teams_link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: #4c53bc;
  text-align: center;
}

.Main_CTA_inner {
  align-items: center;
  justify-content: center;
}

.tag {
  border: none;
  padding: 6px 22px;
  font-weight: bold;
}

.tagWrapper {
  display: flex;
  justify-content: center;
}


.Marketing_popin_wrapper {
  position: relative;

  .Marketing_popin_content_logos {
    justify-content: flex-start;
    align-items: center;
    display: flex;

    img {
      width: 22px;

      &.Marketing_popin_content_logos_teams {
        width: 40px;
        margin-left: 8px;
      }
    }
  }

  .Marketing_popin_picture_wrapper {
    position: absolute;
    right: 0;
    display: flex;
    align-items: flex-start;
    height: 100%;
    padding-top: 20px;
    padding-right: 12px;
    z-index: 0;

    img {
      width: 222px;
      opacity: 1;
    }
  }

  .Marketing_popin_content_wrapper {
    z-index: 1;
    color: #000000c2;
    width: 100%;
    padding: 6px 24px;
    padding-bottom: 32px;
    position: relative;

    h3 {
      max-width: 280px;
      line-height: 44px;
    }
  }

  .Marketing_popin_action_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    z-index: 10;
    position: relative;
    background-color: white;
    height: 46px;
    overflow: hidden;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .Marketing_popin_action_element {
      border-right: 1px solid #bcbcbc;
      padding-left: 12px;
      padding-right: 12px;
      flex-basis: 50%;
      text-align: center;
      height: 100%;
      padding-top: 14px;
      padding-bottom: 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 15px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .Marketing_popin_action_element_primary {
      background-color: #69309c;

      span {
        color: white;
      }
    }
  }
}

.Billy_added {
  text-align: left;
  padding: 24px;
  margin-top: 24px;
  align-items: center;
  background: white;
  margin-bottom: 24px;
  border-radius: 12px;

}

.Billy_addedInner {
  display: flex;
  justify-content: center;
}

.bold {
  font-weight: 700;
}

.ant-btn {
  font-size: 15px;
  height: 40px;
}

.ant-btn-lg {
  font-size: 18px;
  padding: 24px 22px;
  display: flex;
  align-items: center;
  text-align: center;

  &.ant-btn-block span {
    margin-left: auto;
    margin-right: auto;
  }

  span:hover {
    text-decoration: none;
  }

  &:hover span {
    text-decoration: none;
  }
}

a:hover>.ant-btn-lg>span {
  text-decoration: none;
}

.hint div {
  font-size: 16px;
  margin-top: 6px;
  background-color: #b9c5ff;
  padding: 3px 10px;
  border-radius: 18px;
  color: #000000;
}

.title {
  margin-bottom: 16px;
}


.Page_inner {
  padding-top: 40px;
  padding-bottom: 40px;
  margin-left: 12px;
  margin-right: 12px;
}

.Free_hint {
  div {
    margin-left: 12px;
    margin-right: 12px;
  }
}

.MarketingBanner_wrapper {
  background-color: #dc1e5a;
  color: white;
  padding: 4px 12px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  font-size: 17px;
}

.Preview_inner {
  width: 890px;
  max-width: 100%;
  margin: auto;

  .Preview_title {
    width: 750px;
    max-width: 100%;
    margin: auto;
    margin-bottom: 52px;
    margin-top: 34px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }
  }

  .Field_hint {
    font-style: italic;
    color: grey;
    font-size: 11px;
  }

  .Preview_action_item {
    display: flex;
    align-items: center;
    margin-right: 22px;
    margin-left: 22px;
  }

  .Preview_actions {
    display: flex;
    text-align: center;
    margin: auto;
    margin-bottom: 24px;
    margin-top: 24px;
    max-width: 100%;
    justify-content: center;

    .ant-btn-lg {
      margin: initial;
      margin-left: 12px;
      padding: 18px 22px;
    }
  }
}

.Preview_screen img {
  width: 600px;
  max-width: 100%;
}

.Preview_screen2 img {
  width: 600px;
  max-width: 100%;
}

.Preview_screen3 img {
  width: 600px;
  max-width: 100%;
}


.Page_wrapper {
  position: relative;
}

.Page_header_wrapper {
  position: relative;
  text-align: center;
  padding: 60px 36px;
  background: linear-gradient(116deg,
      #ba82f5db,
      #a492e8db,
      #6374f3db,
      #8f77e5db);

  h1 {
    color: white;
  }
}

.Page_header_description {
  color: white;
  width: 580px;
  max-width: 100%;
  margin: auto;
  margin-top: 24px;
}

.Page_header_alternative {
  background: linear-gradient(116deg,
      #9559d4db,
      #8371c8db,
      #4453c6db,
      #6b59abed);
}


.Arguments_list {}

.CTA_hint {
  font-size: 14px;
  color: #f1f1f1;
  margin-top: 4px;
}

.HRQuote {
  text-align: center;
  font-size: 27px;
  width: 620px;
  max-width: 100%;
  margin: auto;
  margin-top: 71px;
  font-weight: lighter;
}


.flexArg {
  margin: auto;
  display: flex;
  justify-content: center;
  width: 860px;
  max-width: 100%;
  font-size: 18px;

  .flexLeft {
    flex-basis: 50%;
    padding: 20px;
  }

  .flexRight {
    flex-basis: 50%;
    padding: 20px;

    color: #4f4f4f;
  }
}

.Colored_title {
  background: linear-gradient(270deg,
      #00a7ff 10.33%,
      #be14e6 34.36%,
      #ff6500 60.15%,
      #7600ff 84.06%,
      #7494ff 92.67%,
      #a500ff 103.14%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 20px;
}

.Page_title {
  width: 1050px;
  max-width: 100%;
  margin: auto;
}

.Page_subtitle {
  width: 500px;
  max-width: 100%;
  margin: auto;
  text-align: center;
}

.Alternative_list {
  display: flex;
  width: 1200px;
  max-width: 100%;
  margin: auto;
  justify-content: center;
  margin-top: 44px;
  flex-wrap: wrap;
}

.Alternative_vignette_wrapper {
  width: 340px;
  margin: 12px;
  max-width: 100%;
  min-height: 200px;
  background-color: white;
  text-align: center;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    .Alternative_vignette_inner {
      .Alternative_vignette_title {
        text-decoration: none;
      }
    }
  }

  .Alternative_vignette_inner {
    padding: 20px;

    .Alternative_vignette_title {
      font-weight: bold;
      color: #4f4f4f;
      text-decoration: none !important;
    }

    .Alternative_vignette_image_wrapper {
      min-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .Alternative_vignette_image {
      width: 200px;
      height: auto;
    }

    .Alternative_vignette_link {
      margin-top: 16px;
      color: #4f4f4f;
    }
  }
}

.Top_header_actions {
  justify-content: center;
  margin-top: 22px;
  align-items: center;
  margin-bottom: 16px;
  display: flex;

  .Top_header_actions_link {
    margin-right: 12px;
  }
}

.Mobile_menu .ant-menu-item a {
  font-size: 16px;
}

.Free_plan_vignette_wrapper {
  margin: auto;
  max-width: calc(100% - 24px);
  width: 800px;
  margin-bottom: 14px;

  .Free_plan_vignette_inner {
    padding: 42px 60px;
    margin: 12px;
  }

  .Free_plan_vignette_title {
    font-weight: bold;
    font-size: 34px;
    margin-bottom: 12px;
  }

  .Free_plan_vignette_description {
    margin-top: 12px;
    font-size: 18px;
  }

  .Free_plan_vignette_actions {
    display: flex;
    margin-top: 24px;
  }
}

.Startup_page_free_panel {
  width: 600px;
  max-width: 100%;
  margin: auto;
  margin-top: 24px;

  .ant-alert {
    margin-left: 12px;
    margin-right: 12px;
  }
}

.Alternative_introduction_panel {
  padding: 70px;
  background-color: white;
  width: 980px;
  margin: auto;
  max-width: 100%;
  border-radius: 12px;
  margin-bottom: 48px;

  .Alternative_introduction_title {
    font-size: 26px;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 950px) {
  .Screens_wrapper {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

    .Screen_capture_1 {
      margin-right: 0px;
      margin-left: 0px;
      margin-bottom: -34px;
      z-index: 1;
      max-width: calc(100% - 68px);

      .Screen_capture {
        width: 100%;
        height: auto;
      }

      .Screens_icon {
        position: absolute;
        top: -60px;
        left: -124px;
        font-size: 95px;
        z-index: 1;
      }
    }

    .Screen_capture_2 {
      z-index: 0;

    }
  }


  .Benefits_element {
    flex-basis: 90%;
  }

  .Success_icon img {
    width: 150px;
  }

  .UsedBy_inner {
    img {
      margin-left: 18px;
      margin-right: 18px;
    }
  }
}

@media screen and (max-width: 800px) {

  .Deco img {
    opacity: 0.15;
  }

  .Main_title {
    margin-top: 10px;
    font-size: 35px;
    line-height: 46px;
  }

  .First_panel_section {
    padding-top: 36px;
    padding-bottom: 56px;
  }

  .Screens_wrapper {
    .Screen_capture_1 {
      .Screens_icon {
        top: -60px;
        left: -64px;
      }
    }
  }
}

@media screen and (max-width: 690px) {

  .flexArg {
    flex-wrap: wrap;

    .flexLeft {
      flex-basis: 100%;
      justify-content: center;
    }

    .flexRight {
      flex-basis: 100%;
      justify-content: center;
    }

    .Hide_mobile {
      display: none;
    }
  }

  .Preview_inner .Preview_actions {
    flex-wrap: wrap;

    .Preview_action_item {
      flex-wrap: wrap;
      margin-top: 12px;
      margin-right: 8px;
      margin-left: 8px;
    }
  }
}

@media screen and (max-width: 670px) {
  .Billy_addedInner {
    flex-wrap: wrap;
  }

}


@media screen and (max-width: 550px) {
  .titleSuccessWrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  .titleSuccess {
    margin-left: 0px;
    flex-basis: 100%;
    text-align: center;
    margin-top: 12px;
  }

  .Marketing_popin_wrapper {
    .Marketing_popin_picture_wrapper {
      img {
        opacity: 0.1;
      }
    }
  }

  .Main_title_wrapper {
    margin-left: 8px;
    margin-right: 8px;
    max-width: calc(100% - 16px);
  }

  .Main_CTA_wrapper {
    flex-wrap: wrap-reverse;
  }

  .Screens_wrapper {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

    .Screen_capture_1 {
      .Screens_icon {
        top: -120px;
        left: -4px;
      }
    }

    .Screen_capture_2 {
      max-width: 75%;

      img {
        max-width: 100%;
        height: auto;
      }

      .Screens_icon_2 {
        right: 30px;
        font-size: 70px;
      }
    }
  }
}

@media screen and (max-width: 500px) {


  .Number_installs_wrapper {
    .Number_installs_icon {
      left: -32px;
    }

    .Number_installs_inner {
      padding: 4px 14px;

      .Number_installs_content {
        padding-left: 10px;
        font-size: 14px;
      }
    }
  }

  .Top_header_actions {
    flex-wrap: wrap-reverse;

    >div {
      flex-basis: 100%;
    }

    .Top_header_actions_link {
      margin-top: 12px;
    }
  }


}

@media screen and (max-width: 460px) {
  .Header_marketing_margin {
    margin-top: 88px;
  }

  .MarketingBanner_wrapper {
    flex-wrap: wrap;
    height: 88px;
  }

}